import Core from '@/core/module';
import GA from '@/helpers/ga';

export default class MobileMenu extends Core {
    init() {
        this.setDefaults({
            container: '.mobile-topbar',
            toggleElement: '.mobile-topbar__toggle',
            isOpen: false,
        });

        this.addEventListeners();
    }

    addEventListeners() {
        $(this.options.toggleElement).on('click.MobileMenu', (e) => {
            e.preventDefault();
            this.toggle();
        });

        $(window).on('resize.MobileMenu', () => {
            if ($(window).width() > 1020) {
                $('body').removeClass('no-scroll');
            }
        });

        $('.submenu-toggle').on('click', (e) => {
            e.preventDefault();
            $(e.target).next().slideToggle();
            $(e.target).toggleClass('submenu-toggle--open');

            if ($(e.target).attr('aria-expended') === 'false') {
                $(e.target).attr('aria-expended', 'true');
            } else {
                $(e.target).attr('aria-expended', 'false');
            }
        });
    }

    removeEventListeners() {
        $(this.options.toggleElement).off('click.MobileMenu');
        $(window).off('resize.MobileMenu');
    }

    toggle() {
        if (this.options.isOpen) {
            this.close();
            this.options.isOpen = false;
        } else {
            this.open();
            this.options.isOpen = true;
        }
    }

    open() {
        if (this.options.isOpen) {
            return;
        }

        $('body').addClass('no-scroll');

        this.options.isOpen = true;
        $(this.options.container).addClass('show');
        $(this.options.toggleElement).addClass('open');

        GA.event({
            category: 'mobile menu',
            action: 'click',
            label: 'open',
        });
    }

    close() {
        if (!this.options.isOpen) {
            return;
        }

        $('body').removeClass('no-scroll');

        this.options.isOpen = false;
        $(this.options.container).removeClass('show');
        $(this.options.toggleElement).removeClass('open');

        GA.event({
            category: 'mobile menu',
            action: 'click',
            label: 'close',
        });
    }
}
