import Swiper, { Autoplay, Pagination } from 'swiper';
import Core from '@/core/module';

Swiper.use([Autoplay, Pagination]);

export default class LogoSlider extends Core {
    init() {
        const nrOfSlides = this.$el.find('.swiper-slide').length;

        this.swiper = new Swiper(this.$el.find('.swiper-container')[0], {
            slidesPerView: 3,
            spaceBetween: 20,
            freeMode: true,
            roundLengths: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            loop: nrOfSlides >= 6,
            centerInsufficientSlides: true,
            speed: 1000,
            breakpoints: {
                640: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
                1200: {
                    slidesPerView: 6,
                    spaceBetween: 100,
                },
            },
        });
    }
}
