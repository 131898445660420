import { Swiper, Navigation } from 'swiper';
import Core from '@/core/module';

Swiper.use([Navigation]);

export default class articleSlider extends Core {
    init() {
        setTimeout(() => {
            this.swiper = new Swiper(this.$el.find('.swiper-container')[0], {
                slidesPerView: 1.5,
                autoHeight: true,
                loop: false,
                speed: 500,
                roundLengths: true,
                spaceBetween: 10,
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 34,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 34,
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 34,
                    },
                },
                navigation: {
                    nextEl: '.featured-articles__btn--next',
                    prevEl: '.featured-articles__btn--prev',
                },
            });
        }, 50);
    }
}
