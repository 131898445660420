import Core from '@/core/module';

export default class TarifCalculator extends Core {
    init() {
        const obj = this.$el.data('object');
        let type = Object.keys(obj)[0];
        let selectedRangeIndex = 0;
        const typeSelection = this.$el.find('input[type=radio][name=type]');
        const amountSelection = this.$el.find('#select');
        const frankingSelection = this.$el.find('input[type=checkbox][name=franking]');
        const frankingContainer = this.$el.find('#franking-container');
        const frankingBox = this.$el.find('#franking-box');
        const actionBox = this.$el.find('#tarif-action');
        // const priceFrank = 'pricePerItemWithFranking';
        const priceFrank = 'pricePerItemNoFranking';
        const priceNoFrank = 'pricePerItemNoFranking';

        let priceType = priceNoFrank;

        this.setFrankingContainer(frankingContainer, frankingSelection, frankingBox, type);
        this.selectValueChange(0, obj, type, priceType);
        this.createOptions(obj, type, amountSelection);

        typeSelection.on('change', (e) => {
            document.location.href = '#price-anchor';
            amountSelection.empty();
            type = e.target.value;
            if (type === 'mail') {
                actionBox.hide();
            } else {
                actionBox.show();
            }
            this.createOptions(obj, type, amountSelection);
            this.selectValueChange(0, obj, type, priceType);
            this.setFrankingContainer(frankingContainer, frankingSelection, frankingBox, type);
        });

        amountSelection.on('change', (e) => {
            selectedRangeIndex = e.target.value;
            this.selectValueChange(selectedRangeIndex, obj, type, priceType);
        });

        frankingSelection.on('change', (e) => {
            const checked = e.target.checked;
            if (checked) {
                priceType = priceNoFrank;
                frankingBox.show();
            } else {
                priceType = priceFrank;
                frankingBox.hide();
            }
            this.selectValueChange(selectedRangeIndex, obj, type, priceType);
        });
    }

    selectValueChange(newVal, obj, type, priceType) {
        const selectedValue = obj[type][newVal];
        const price = selectedValue[priceType];
        this.$el.find('#price').html(this.formatPrice(price));
        this.$el.find('#quantity').text(selectedValue.label);
        this.$el.find('#type').text(isNaN(price) ? '' : this.getTranslation(type));
        this.$el.find('#type_multiple').text(this.getTranslation(type, 'multiple'));
    }

    createOptions(obj, type, amountSelection) {
        obj[type].forEach((val, i) => {
            const opt = document.createElement('option');
            opt.value = i;
            opt.innerHTML = val.label;
            amountSelection.append(opt);
        });
    }

    setFrankingContainer(frankingContainer, frankingSelection, frankingBox, type) {
        frankingSelection.prop('checked', false);
        frankingBox.hide();
        if (type === 'mail') {
            frankingContainer.show();
        } else {
            frankingContainer.hide();
        }
    }

    formatPrice(number) {
        if (isNaN(number)) {
            return `<a href="/contact/zomeractie" class="price-box__price-text">${number}</a>`;
        }
        let price = parseFloat(number).toFixed(3);
        price = `€ ${String(price).replace('.', ',').replace(/\.?0+$/, '')}`;
        return price;
    }

    getTranslation(type, how) {
        let translation;
        if (how === 'multiple') {
            if (type === 'package') translation = 'pakketten';
            else if (type === 'mail') translation = 'poststukken';
            else translation = 'brievenbuspakketten';
        } else translation = 'per stuk';
        return translation;
    }
}
