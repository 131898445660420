import Core from '@/core/module';

export default class FAQ extends Core {
    init() {
        this.$el.find('.js-faq-question').on('click', (e) => {
            e.preventDefault();
            $(e.target).siblings().stop().slideToggle();
        });
    }
}
