import Core from '@/core/module';
import Cookie from '@/helpers/cookie';

export default class AlertPopup extends Core {
    init() {
        this.setDefaults({
            close: '.alert-popup .close',
            submit: '.alert-popup .btn',
            defaultTimeout: 3,
        });

        this.addEventListeners();

        window.showAlertPopup = () => {
            this.runTimer();
        };
    }

    addEventListeners() {
        $(this.options.close).on('click', (e) => {
            e.preventDefault();
            this.close();
        });

        $(this.options.submit).on('click', (e) => {
            e.preventDefault();
            this.close();

            if ($(this.options.submit).attr('href') !== '#') {
                window.location.href = e.target;
            }
        });
    }

    removeEventListeners() {
        this.$el.off('click');
    }

    runTimer() {
        setTimeout(() => {
            this.removeEventListeners();
        }, 1000);

        if (!Cookie.get('alertmsg-popup_hide', Boolean)) {
            if (Cookie.get('alertmsg-popup_timeout', Number) === 0) {
                this.open();
            } else {
                Cookie.set('alertmsg-popup_animate', {
                    expiration: 1,
                    value: 1,
                });

                let currentTimeout = Cookie.get('alertmsg-popup_timeout', Number);

                if (!currentTimeout || currentTimeout < 0) {
                    currentTimeout = this.options.defaultTimeout;
                }

                const alertPopupTimer = setInterval(() => {
                    if (currentTimeout === 0) {
                        clearInterval(alertPopupTimer);

                        this.open();
                    }

                    Cookie.set('alertmsg-popup_timeout', {
                        expiration: 1,
                        value: currentTimeout,
                    });

                    currentTimeout -= 1;
                }, 1000);
            }
        } else {
            $(this).remove();
        }
    }

    open(noanimate) {
        if (noanimate) {
            this.$el.css('bottom', 0).show();
            return false;
        }

        this.$el.css('bottom', -this.$el.outerHeight()).show();
        this.$el.animate({ bottom: 0 }, 350);

        return this;
    }

    close() {
        Cookie.set('alertmsg-popup_hide', {
            expiration: 1,
            value: 1,
        });
        this.$el.animate({ bottom: -this.$el.outerHeight() }, 350, () => {
            this.$el.remove();
        });
    }
}
