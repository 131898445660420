import Core from '@/core/module';

export default class StickyMenu extends Core {
    init() {
        const menu = document.querySelector('.js-sticky-menu');
        menu.addEventListener('click', () => {
            menu.classList.toggle('active');
        });
    }
}
