import '../../sass/index.scss';
import _ from 'lodash';
import bugsnag from '@bugsnag/js';
import ModuleArticleSlider from '@/modules/articleslider';
import Router from '@/core/router';
import ModuleBacktotop from '@/modules/backtotop';
import TarifCalculator from '@/modules/tarifcalculator';
import ModuleMobileMenu from '@/modules/mobilemenu';
import ModuleAlertPopup from '@/modules/alertpopup';
import ModuleLogoSlider from '@/modules/logoslider';
import ModuleFAQ from '@/modules/faq';
import StickyMenu from '@/modules/sticky-menu';
import CsrfToken from '@/modules/csrftoken';

if (process.env.BUGSNAG) {
    // window.bugsnagClient = bugsnag(process.env.BUGSNAG);
    window.bugsnagClient = bugsnag.start({ apiKey: process.env.BUGSNAG });
}

window._ = {
    escape: _.escape,
};

Router.setup({
    options: {
        pagecontainer: '.page-container',
        defaultTransition: 'fade',
        ajaxloading: false,
    },
    modules: [
        {
            class: CsrfToken,
            element: '.csrf-token',
        },
        {
            class: ModuleMobileMenu,
            element: '.mobile-topbar',
        },
        {
            class: ModuleLogoSlider,
            element: '.ourclients',
        },
        {
            class: ModuleArticleSlider,
            element: '.featured-articles',
        },
        {
            class: ModuleFAQ,
            element: '.faq',
        },
        {
            class: ModuleFAQ,
            element: '.sticky-faq',
        },
        {
            class: StickyMenu,
            element: '.js-sticky-menu',
        },
        {
            class: ModuleBacktotop,
            element: 'body',
        },
        {
            class: TarifCalculator,
            element: '#tarif-calculator',
        },
        {
            class: ModuleAlertPopup,
            element: '.alert-popup',
        },
        {
            load: () => import(/* webpackChunkName: "stickybar" */ '@/modules/stickybar'),
            element: '.sticky-bar',
        },
        {
            load: () => import(/* webpackChunkName: "ajaxform" */ '@/modules/ajaxform/contact'),
            element: '[ajaxform]',
        },
        {
            element: '#impactmeter',
            load: () => import(
                /* webpackChunkName: "impactmeter" */ '@/modules/impactmeter/index.tsx'
            ),
        },
        {
            element: '.impactmeter-widget-panel',
            load: () => import(
                /* webpackChunkName: "impactmeterwidget" */ '@/modules/impactmeterwidget'
            ),
        },
        {
            load: () => import(/* webpackChunkName: "cookieconsent" */ '@/modules/cookie'),
            element: '#cookieconsent',
        },
        {
            load: () => import(
                /* webpackChunkName: "trackandtrace" */ '@/modules/trackandtrace'
            ),
            element: '.trackandtrace',
        },
        {
            load: () => import(
                /* webpackChunkName: "klantenvertellenreviews" */ '@/modules/klantenvertellenreviews'
            ),
            element: '.klantenvertellen-reviews',
        },
        {
            load: () => import(/* webpackChunkName: "delaymap" */ '@/modules/delaymap'),
            element: '.delaymap',
        },
    ],
});
