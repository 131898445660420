import Core from '@/core/module';
import GA from '@/helpers/ga';
import Template from './template.html';

export default class backtotop extends Core {
    init() {
        this.setDefaults({
            backtotopIsVisible: 'backtotop--is-visible',
            offset: 600,
            scrollTopDuration: 0.7,
        });

        this.$template = $(Template());

        this.$el.append(this.$template);

        this.addEventListeners();
    }

    addEventListeners() {
        $(window).on('scroll.backToTop', () => {
            const scrollTop = $(window).scrollTop();

            if (scrollTop > this.options.offset) {
                this.$template.addClass(this.options.backtotopIsVisible);
            } else {
                this.$template.removeClass(this.options.backtotopIsVisible);
            }
        });

        $(window).on('mousewheel.backToTop', () => {
            $('body, html').stop();
        });

        this.$template.on('click.backToTop', () => {
            GA.event({
                category: 'button backtotop',
                action: 'click',
            });
        });
    }

    removeEventListeners() {
        $(window).off('scroll.backToTop');
        this.$template.on('click.backToTop');
    }
}
